// Colors
$style: #1e30ca;
$brown: #b7853f;
$brickred: #B3182A;
$green: #3E5902;
$purple:#483A6F;
$darkbrown: #621529;
$bilobaflower: #F2B47E;
$lightblue: #479af1;
$pink: #e83e8c;
$red: #ff4c3b;
$panetone: #d4b196;
$lightbrown: #866e6c;
$lightpink: #dc457e;
$smokegray: #6d7e87;
$parrot: #81ba00;
$ferozi:  #01effc;
$moss: #5d7227;
$lightferozi: #5fcbc4;
$seapink: #e38888;
$darkblue: #354f9f;
$darkgray: #767676;
$orange: #b5362c;
$voiltsky: #4fbbd0;
$yellow: #EDD562;

 
.demo-panel {
    position: fixed;
    left: 0;
    top: 30px;
    z-index: 9999;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    .demo-panel-trigger {
        position: absolute;
        top: 32px;
        left: 100%;
        width: 40px;
        height: 40px;
        line-height: 40px;
        background-color: $text-light;
        margin-left: -1px;
        text-align: center;
        font-size: 17px;
        color: $body-color;
        cursor: pointer;
        z-index: 1;
        border-radius: 0 4px 4px 0;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    }
    .demo-panel-inner {
        position: relative;
        width: 200px;
        background-color: $body-bg;
        font-size: 14px;
        border: 1px solid $border-color;
        z-index: 2;
        .demo-panel-header {
            background-color: #fff;
            
            text-align: center;
            font-size: $font-size-lg;
            font-family: $font-family-base-bold;
            border-bottom: 1px solid $border-color;
            color: $body-color;
            padding: 5px 15px;
        }
       .demo-panel-content{
           padding: 5px;
         h3 {
             margin-top: 10px;
            margin-bottom: 5px;
            font-size: $font-size-lg;
            color: $body-color;
            position: relative;
             span {
                background-color: $body-bg;
                position: relative;
                z-index: 2;
                padding-right: 10px;
            }
            &::after{
                content: "";
                border-bottom: 1px solid #212121;
                position: absolute;
                left: 0;
                width: 100%;
                top: 8px;
            }
           }
        }
       .form-group {
            margin-bottom: 8px;
            .select-group {
                padding-right: 30px;
                position: relative;
            }
           .form-control {
                border: 1px solid #ddd !important;
                -webkit-appearance: caret !important;
                appearance: caret !important;
                height: 32px;
                padding: 3px 5 px;
            }
            .view {
                position: absolute;
                right: 5px;
                top: 4px;
                .quick-view i {
                    color: #212121;
                }
            }
        }
    }
}
.demo-panel.demo-panel-open {
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
}
.demo-panel-trigger .dpt-icon {
    display: inline-block;
}
.demo-panel-trigger .dpt-close {
	display: none;
}
.demo-panel.demo-panel-open .dpt-close {
	display: inline-block;
}
.demo-panel.demo-panel-open .dpt-icon {
	display: none;
}
.custom-radios {
  margin-top: 15px;
    div {
      display: inline-block;
    }
  
    input[type="radio"] {
      display: none;
  
      + label {
        color: #333;
        font-family: Arial, sans-serif;
        font-size: 14px;
        margin-bottom: 0;
  
        span {
          display: inline-block;
          width: 18px;
          height: 18px;
          margin: 0px 2px 2px 0;
          vertical-align: middle;
          cursor: pointer;
          background-repeat: no-repeat;
          background-position: center;
          text-align: center;
      
          img {
            opacity: 0;
            transition: all .3s ease;
            width: 12px;
            margin-top: -5px; 
          }
        }
      }
  
      &#style + label span {
        background-color: $style;
      }
  
      &#brown + label span {
        background-color: $brown;
      }
  
      &#brickred + label span {
        background-color: $brickred;
      }
  
      &#green + label span {
        background-color: $green;
      }
      &#purple + label span {
        background-color: $purple;
      }
      &#darkbrown + label span {
        background-color: $darkbrown;
      }
      &#bilobaflower + label span {
        background-color: $bilobaflower;
      }
      &#lightblue + label span {
        background-color: $lightblue;
      }
      &#pink + label span {
        background-color: $pink;
      }
      &#red + label span {
        background-color: $red;
      }
      &#panetone + label span {
        background-color: $panetone;
      }
      &#lightbrown + label span {
        background-color: $lightbrown;
      }
      &#lightpink + label span {
        background-color: $lightpink;
      }
      &#smokegray + label span {
        background-color: $smokegray;
      }
      &#parrot + label span {
        background-color: $parrot;
      }
      &#ferozi + label span {
        background-color: $ferozi;
      }
      &#moss + label span {
        background-color: $moss;
      }
      &#lightferozi + label span {
        background-color: $lightferozi;
      }
      &#seapink + label span {
        background-color: $seapink;
      }
      &#darkblue + label span {
        background-color: $darkblue;
      }
      &#darkgray + label span {
        background-color: $darkgray;
      }
      &#orange + label span {
        background-color: $orange;
      }
      &#voiltsky + label span {
        background-color: $voiltsky;
      }
      &#yellow + label span {
        background-color: $yellow;
      }
  
      &:checked + label span img {
        opacity: 1;
      } 
    }
}
  .quick-view-hover {
    display: none;
    position: absolute;
    left: 160%;
    background-color: red;
    z-index: 99;
    top: 0;
    transform: translateY(-50%);
    img {
        width: 425px;
        border: 2px solid var(--color-border);
    }
}
.quick-view:hover + .quick-view-hover {
    display: block;
}